html,
body {
    height: auto;
    min-height: 100%;
    margin: 0;
    overflow-x: hidden;
}

.candidates-container {
    background-color: #ffffff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    padding: 20px;
    overflow: auto;
}

.candidates-title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333333;
}

.candidates-table {
    width: 100%;
    table-layout: auto;
}

.candidates-table th,
.candidates-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.candidates-table th {
    background-color: #f4f4f9;
    color: #555555;
    font-weight: bold;
}

.candidates-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.candidates-table tr:hover {
    background-color: #f1f1f1;
}

.no-candidates-message {
    font-size: 16px;
    color: #777777;
    margin-top: 20px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 8px 16px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.page-info {
    font-size: 16px;
    color: #333333;
}